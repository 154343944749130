import { IconButton, Tooltip } from '@material-ui/core';
import DataGrid, {
  HeaderFilter,
  Scrolling,
  Column,
  Editing,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { BoardBody } from '../../components/BoardBody';
import api from '../../services/api';
import { Container } from './styles';

export const Systems: React.FC = () => {
  const [systems, setSystems] = useState<DataSource>();

  const [open, setOpen] = useState(false);

  const loadSystems = useCallback(async () => {
    const systemsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/integration-systems');
        return data;
      },
      insert: async data => {
        await api.post('/api/integration-systems', data);
      },
      update: async (id, data) => {
        await api.put(`/api/integration-systems/${id}`, {
          name: data.name,
          isActive: data.isActive,
        });
      },
    });

    setSystems(
      new DataSource({
        store: systemsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadSystems();
  }, [loadSystems]);

  const boldTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const onToolbarPreparing = useCallback(e => {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach((item: any) => {
      if (item.name === 'addRowButton') {
        item.showText = 'always';
        item.options = {
          ...item.options,
          text: 'NEW SYSTEM',
        };
      }
    });
  }, []);

  const openDetailDialog = useCallback(e => {
    return (
      <Tooltip title="Open details" aria-label="open">
        <IconButton
          aria-label="Open details"
          size="small"
          onClick={() => window.open(`/systems/${e.key}`, `Window${e.key}`)}
        >
          <FiExternalLink size={22} color="#8b0304" />
        </IconButton>
      </Tooltip>
    );
  }, []);

  return (
    <Container>
      <BoardBody>
        <DataGrid
          hoverStateEnabled
          keyExpr="id"
          dataSource={systems}
          onCellClick={e => {
            if (e.rowType === 'data' && !e.row.isEditing) {
              window.open(`/systems/${e.key}`, `Window${e.key}`);
            }
          }}
          onToolbarPreparing={onToolbarPreparing}
          onRowUpdating={options => {
            options.newData = { ...options.oldData, ...options.newData };
          }}
        >
          <HeaderFilter visible allowSearch />
          <Editing allowAdding allowUpdating />
          <Scrolling mode="infinite" />
          <Column
            dataField="name"
            caption="System Name"
            cellRender={boldTextCells}
          />
          <Column dataField="logo" caption="Logo" visible={false} />
          <Column dataField="isActive" />
          <Column cellRender={openDetailDialog} width={60} alignment="right" />
        </DataGrid>
      </BoardBody>
    </Container>
  );
};
