import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import {
  FiHome,
  FiSettings,
  FiUser,
  FiLayout,
  FiUsers,
  FiBarChart2,
  FiLogOut,
} from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';

import {
  Container,
  Control,
  ControlComponent,
  NavigationItemsArea,
  ActionIconWrapper,
  NotificationsComponent,
  NavigationDialogItemComponent,
} from './styles';
import itcGroups from '../../config/itcGroups';

export const Surface: React.FC = () => {
  const history = useHistory();

  const { signOut, hasScopes } = useAuth();

  const handleHome = useCallback(() => {
    history.push('/');
  }, [history]);

  const handleProfessional = useCallback(() => {
    history.push('/professionals');
  }, [history]);

  const handleSystems = useCallback(() => {
    history.push('/systems');
  }, [history]);

  const handleGroups = useCallback(() => {
    history.push('/groups');
  }, [history]);

  const handleAdmin = useCallback(() => {
    history.push('/admin');
  }, [history]);

  const handleDashboard = useCallback(() => {
    history.push('/dashboard');
  }, [history]);

  return (
    <Container>
      <Control>
        <ControlComponent>
          <NavigationItemsArea>
            <ActionIconWrapper>
              <NotificationsComponent>
                <span>
                  <div className="iconContainer">
                    <Tooltip title="Home" placement="right" color="red">
                      <NavigationDialogItemComponent onClick={handleHome}>
                        <FiHome color="#9D9D9D" />
                      </NavigationDialogItemComponent>
                    </Tooltip>
                  </div>
                  <div className="iconContainer">
                    <Tooltip
                      title="Professionals"
                      placement="right"
                      color="red"
                    >
                      <NavigationDialogItemComponent
                        onClick={handleProfessional}
                      >
                        <FiUser color="#9D9D9D" />
                      </NavigationDialogItemComponent>
                    </Tooltip>
                  </div>
                  <div className="iconContainer">
                    <Tooltip title="Systems" placement="right" color="red">
                      <NavigationDialogItemComponent onClick={handleSystems}>
                        <FiLayout color="#9D9D9D" />
                      </NavigationDialogItemComponent>
                    </Tooltip>
                  </div>
                  <div className="iconContainer">
                    <Tooltip title="Groups" placement="right" color="red">
                      <NavigationDialogItemComponent onClick={handleGroups}>
                        <FiUsers color="#9D9D9D" />
                      </NavigationDialogItemComponent>
                    </Tooltip>
                  </div>
                  <div className="iconContainer">
                    <Tooltip title="Dashboard" placement="right">
                      <NavigationDialogItemComponent onClick={handleDashboard}>
                        <FiBarChart2 color="#9D9D9D" />
                      </NavigationDialogItemComponent>
                    </Tooltip>
                  </div>
                  {hasScopes([itcGroups.Admin]) && (
                    <div className="iconContainer">
                      <Tooltip title="Admin" placement="right">
                        <NavigationDialogItemComponent onClick={handleAdmin}>
                          <FiSettings color="#9D9D9D" />
                        </NavigationDialogItemComponent>
                      </Tooltip>
                    </div>
                  )}
                  <div className="iconContainer">
                    <Tooltip title="Log out" placement="right">
                      <NavigationDialogItemComponent
                        onClick={signOut}
                        style={{ position: 'absolute', bottom: 32 }}
                      >
                        <FiLogOut color="#9D9D9D" />
                      </NavigationDialogItemComponent>
                    </Tooltip>
                  </div>
                </span>
              </NotificationsComponent>
            </ActionIconWrapper>
          </NavigationItemsArea>
        </ControlComponent>
      </Control>
    </Container>
  );
};
