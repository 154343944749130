import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;px;
  margin: 64px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  padding: 20px;
`;

export const Buttons = styled.button`
  height: 120px;
  border: 1.5px solid #c8c8c8;
  box-shadow: 4px 1px #c8c8c8;
  border-radius: 6px;

  margin: 18px;

  color: #c8c8c8;

  font-size: 14px;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: border-color 0.4s;

  &:hover {
    border-color: ${darken(0.1, '#c8c8c8')};
    color: ${darken(0.1, '#c8c8c8')};
  }

  cursor: pointer;
`;
