import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  align-items: baseline;
  overflow: auto;

  .dx-datagrid,
  .dx-datagrid-header-panel,
  .dx-toolbar {
    background-color: #efefef;
  }

  .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table .dx-row {
    background-color: #fff;
  }

  .dx-datagrid-headers .dx-header-row .dx-datagrid-text-content {
    font-weight: 700;
    color: #333;
    font-size: 14px;
  }
`;
