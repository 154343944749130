import React from 'react';

import { Container, ContentWrapper, Content, Board } from './styles';

export const ApplicationContent: React.FC = ({ children }) => {
  return (
    <Container>
      <ContentWrapper>
        <Content>
          <Board>{children}</Board>
        </Content>
      </ContentWrapper>
    </Container>
  );
};
