import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import { SignIn } from '../pages/SignIn';
import { Home } from '../pages/Home';
import { ApplicationLayers } from '../components/ApplicationLayers';
import { Professionals } from '../pages/Professionals';
import { Systems } from '../pages/Systems';
import { SystemDetails } from '../pages/Systems/SystemDetails';
import { Groups } from '../pages/Groups';
import { Admin } from '../pages/Admin';
import { Dashboard } from '../pages/Dashboard';
import { ProfessionalDetails } from '../pages/ProfessionalDetails';
import { Segments } from '../pages/Segments';
import { Methodologies } from '../pages/Methodologies';
import { Languages } from '../pages/Languages';
import { Toolkits } from '../pages/Toolkits';
import { Geographies } from '../pages/Geographics';
import { IntegrationGroups } from '../pages/IntegrationGroups';
import { useAuth } from '../hooks/auth';

const Routes: React.FC = () => {
  const { user } = useAuth();
  const userScopes = user ? user.scopes : [];

  return (
    <Switch>
      <Route path="/auth/signin" exact component={SignIn} />
      <Route path="/" exact component={SignIn} />

      <ApplicationLayers>
        <Route
          path="/app"
          isPrivate
          exact
          component={Home}
          title="ITC Home"
          scopes={userScopes}
        />
        <Route
          isPrivate
          path="/professionals"
          exact
          component={Professionals}
          title="ITC Professionals"
          scopes={userScopes}
        />
        <Route
          isPrivate
          path="/systems"
          exact
          component={Systems}
          title="ITC Systems"
          scopes={userScopes}
        />
        <Route
          isPrivate
          path="/systems/:id"
          exact
          component={SystemDetails}
          title="System Details"
          scopes={userScopes}
        />
        <Route
          isPrivate
          path="/groups"
          exact
          component={Groups}
          title="ITC Groups"
          scopes={userScopes}
        />
        <Route
          isPrivate
          path="/admin"
          exact
          component={Admin}
          title="ITC Admin"
          scopes={userScopes}
        />
        <Route
          isPrivate
          path="/professionals/:id"
          exact
          component={ProfessionalDetails}
          title="ITC - Professional Details"
          scopes={userScopes}
        />
        <Route
          isPrivate
          path="/dashboard"
          exact
          component={Dashboard}
          title="ITC Dashboard"
          scopes={userScopes}
        />
        <Route
          isPrivate
          path="/segments"
          exact
          component={Segments}
          title="ITC Segments"
          scopes={userScopes}
        />
        <Route
          isPrivate
          path="/methodologies"
          exact
          component={Methodologies}
          title="ITC Methodologies"
          scopes={userScopes}
        />
        <Route
          isPrivate
          path="/languages"
          exact
          component={Languages}
          title="ITC Languages"
          scopes={userScopes}
        />
        <Route
          isPrivate
          path="/toolkits"
          exact
          component={Toolkits}
          title="ITC Toolkits"
          scopes={userScopes}
        />
        <Route
          isPrivate
          path="/geographies"
          exact
          component={Geographies}
          title="ITC Geographies"
          scopes={userScopes}
        />
        <Route
          isPrivate
          path="/integrationgroups"
          exact
          component={IntegrationGroups}
          title="ITC Integration Groups"
          scopes={userScopes}
        />
      </ApplicationLayers>
    </Switch>
  );
};

export default Routes;
