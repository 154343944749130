import React, { useCallback } from 'react';
import {
  FiUsers,
  FiLifeBuoy,
  FiCommand,
  FiFlag,
  FiTool,
  FiSlack,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Container, Buttons } from './styles';
import itcGroups from '../../config/itcGroups';
import { useAuth } from '../../hooks/auth';

export const AdminButton: React.FC = () => {
  const history = useHistory();

  const { hasScopes } = useAuth();

  const handleSegments = useCallback(() => {
    history.push('/segments');
  }, [history]);

  const handleMethodologies = useCallback(() => {
    history.push('/methodologies');
  }, [history]);

  const handleLanguages = useCallback(() => {
    history.push('/languages');
  }, [history]);

  const handleToolkits = useCallback(() => {
    history.push('/toolkits');
  }, [history]);

  const handleIntegrationGroups = useCallback(() => {
    history.push('/integrationgroups');
  }, [history]);

  const handleGeographies = useCallback(() => {
    history.push('/geographies');
  }, [history]);

  return (
    <Container>
      <Buttons onClick={handleSegments}>
        <FiLifeBuoy size="24" style={{ margin: '5px' }} />
        SEGMENTS
      </Buttons>
      <Buttons onClick={handleMethodologies}>
        <FiCommand size="24" style={{ margin: '5px' }} />
        METHODOLOGIES
      </Buttons>
      <Buttons onClick={handleLanguages}>
        <FiFlag size="24" style={{ margin: '5px' }} />
        LANGUAGES
      </Buttons>
      <Buttons onClick={handleToolkits}>
        <FiTool size="24" style={{ margin: '5px' }} />
        TOOLKITS
      </Buttons>
      <Buttons onClick={handleGeographies}>
        <FiSlack size="24" style={{ margin: '5px' }} />
        GEOGRAPHIES
      </Buttons>
      {hasScopes([itcGroups.Admin]) && (
        <Buttons onClick={handleIntegrationGroups}>
          <FiUsers size="24" style={{ margin: '5px' }} />
          INTEGRATION GROUPS
        </Buttons>
      )}
    </Container>
  );
};
