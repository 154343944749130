import { format } from 'date-fns';
import DataGrid, {
  HeaderFilter,
  Scrolling,
  Column,
  Editing,
  Lookup,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useState } from 'react';
import { BoardBody } from '../../components/BoardBody';
import { BoardHeader } from '../../components/BoardHeader';
import master from '../../services/master';
import { Container } from './styles';

interface PracticeStore {
  store: CustomStore;
  paginate: boolean;
}

export const Methodologies: React.FC = () => {
  const [methodologies, setMethodologies] = useState<DataSource>();
  const [practices, setPractices] = useState<PracticeStore>(
    {} as PracticeStore,
  );
  const [segmentsDetailId, setSegmentsDetailId] = useState(0);

  const [open, setOpen] = useState(false);

  const loadSMethodologies = useCallback(async () => {
    const methodologiesStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('master/methodologies');
        return data.sort((a: any, b: any) => {
          if (a.Name < b.Name) {
            return -1;
          }
          if (a.Name > b.Name) {
            return 1;
          }
          return 0;
        });
      },
      insert: async data => {
        await master.post('master/methodologies', data);
      },
      update: async (id, data) => {
        await master.put(`master/methodologies/${id}`, {
          Name: data.Name,
          IsActive: data.IsActive,
          IDPractice: data.IDPractice,
        });
      },
      remove: async id => {
        await master.put(`/master/methodologies/${id}`, {
          IsActive: false,
        });
      },
    });

    setMethodologies(
      new DataSource({
        store: methodologiesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
    const practicesStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('master/practices');
        return data;
      },
    });

    setPractices({
      store: practicesStore,
      paginate: true,
    });
  }, []);

  useEffect(() => {
    loadSMethodologies();
  }, [loadSMethodologies]);

  const boldTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const entryAtCell = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {format(Date.parse(e.text || new Date()), 'MMM. do, yyyy')}
      </p>
    ),
    [],
  );

  return (
    <Container>
      <BoardHeader title="Methodologies" subtitle="Methodologies" />
      <BoardBody>
        <DataGrid
          keyExpr="id"
          dataSource={methodologies}
          onCellClick={e => {
            if (e.rowType === 'data') {
              setSegmentsDetailId(e.key);
              setOpen(true);
            }
          }}
          onRowUpdating={options => {
            options.newData = { ...options.oldData, ...options.newData };
          }}
        >
          <Editing allowAdding allowUpdating />
          <HeaderFilter visible allowSearch />
          <Scrolling mode="infinite" />
          <Column dataField="Name" cellRender={boldTextCells} />
          <Column
            dataField="IDPractice"
            caption="Practice"
            // visible={false}
          >
            <Lookup dataSource={practices} valueExpr="ID" displayExpr="Name" />
          </Column>

          <Column
            dataField="CreatedAt"
            allowHeaderFiltering={false}
            cellRender={entryAtCell}
            allowEditing={false}
          />
          <Column dataField="IsActive" />
        </DataGrid>
      </BoardBody>
    </Container>
  );
};
