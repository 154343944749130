import { IconButton, Tooltip } from '@material-ui/core';
import DataGrid, {
  HeaderFilter,
  Scrolling,
  Column,
  SearchPanel,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { format } from 'date-fns';
import BrazilIcon from '../../assets/images/brazil-icon.svg';
import UKIcon from '../../assets/images/uk-icon.svg';
import ChileIcon from '../../assets/images/chile-icon.svg';
import ArgentinaIcon from '../../assets/images/argentina-icon.svg';
import MexicoIcon from '../../assets/images/mexico-icon.svg';
import GermanyIcon from '../../assets/images/germany-icon.svg';
import USAIcon from '../../assets/images/usa-icon.svg';
import NoIcon from '../../assets/images/no-icon.svg';
import { BoardBody } from '../../components/BoardBody';
import { ProfessionalLookup } from '../../components/ProfessionalLookup';
import master from '../../services/master';
import { Container, Photo } from './styles';
import { ModalPage } from '../../components/ModalPage';

export const Professionals: React.FC = () => {
  const [professionals, setProfessionals] = useState<DataSource>();

  const officeIcons: any = useMemo(
    () => ({
      Brazil: BrazilIcon,
      UK: UKIcon,
      Chile: ChileIcon,
      Argentina: ArgentinaIcon,
      Germany: GermanyIcon,
      Mexico: MexicoIcon,
      USA: USAIcon,
    }),
    [],
  );

  const loadProfessionals = useCallback(async () => {
    const ProfessionalsStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/professionals', {
          params: {
            where: {
              IsAnyStatus: true,
            },
          },
        });
        return data;
      },
    });

    setProfessionals(
      new DataSource({
        store: ProfessionalsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadProfessionals();
  }, [loadProfessionals]);

  const professionalCell = useCallback(e => {
    return (
      <ProfessionalLookup
        name={e.data.Name}
        login={e.data.Login}
        jobtitle={e.data.JobTitle.Name}
        style={{ cursor: 'pointer' }}
      />
    );
  }, []);

  const boldTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
          textAlign: 'left',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const entryAtCell = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
          textAlign: 'left',
        }}
      >
        {format(Date.parse(e.text || new Date()), 'MMM. do, yyyy')}
      </p>
    ),
    [],
  );

  const officeCell = useCallback(
    e => {
      if (e.data.Offices[0]) {
        const src = officeIcons[e.data.Offices[0].Name];
        return (
          <Tooltip title={e.data.Offices[0].Name}>
            <Photo src={src} />
          </Tooltip>
        );
      }

      return (
        <Tooltip title="No Office">
          <Photo src={NoIcon} />
        </Tooltip>
      );
    },
    [officeIcons],
  );

  const openProfessionalDetails = useCallback(e => {
    return (
      <Tooltip title="Open details" aria-label="open">
        <IconButton
          onClick={() =>
            window.open(`/professionals/${e.key}`, `Window${e.key}`)
          }
          aria-label="Open details"
          size="small"
        >
          <FiExternalLink size={22} color="#8b0304" />
        </IconButton>
      </Tooltip>
    );
  }, []);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
          marginRight: '24px',
        }}
      >
        <ModalPage />
      </div>
      <BoardBody>
        <DataGrid
          dataSource={professionals}
          onCellClick={e => {
            if (e.rowType === 'data') {
              window.open(`/professionals/${e.key}`, `Window${e.key}`);
            }
          }}
          hoverStateEnabled
        >
          <SearchPanel
            visible
            placeholder="Search by name, practice, jobtitle or office"
          />
          <HeaderFilter visible allowSearch />
          <Scrolling mode="infinite" />
          <Column
            dataField="Name"
            caption="Professional"
            cellRender={professionalCell}
            allowGrouping={false}
          />
          <Column
            dataField="Offices[0].Name"
            caption="Office"
            cellRender={officeCell}
            width={120}
            alignment="center"
          />
          <Column
            dataField="Practices[0].Name"
            caption="Practice"
            cellRender={boldTextCells}
            width={220}
          />
          <Column
            dataField="EntryAt"
            allowHeaderFiltering={false}
            cellRender={entryAtCell}
          />
          <Column
            dataField="IsActive"
            width={120}
            dataType="boolean"
            defaultFilterValues={[1]}
          />
          <Column
            cellRender={openProfessionalDetails}
            width={60}
            alignment="right"
          />
          <Column
            dataField="JobTitle.Name"
            caption="JobTitle"
            allowHeaderFiltering={false}
            visible={false}
          />
        </DataGrid>
      </BoardBody>
    </Container>
  );
};
