import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 64px;

  .dx-row {
    cursor: pointer;
  }
  .dx-toolbar .dx-texteditor {
    width: 100% !important;
  }

  .dx-toolbar .dx-toolbar-after {
    padding-left: 0px;
    width: 100% !important;
  }

  .dx-datagrid-search-panel {
    margin-left: 0px;
  }

  .dx-row {
    cursor: pointer;
  }

  .dx-datagrid-addrow-button {
    background-color: #8b0304;
    color: white;
  }

  .dx-datagrid-addrow-button.dx-state-hover {
    background-color: #650001;
  }

  .dx-icon-edit-button-addrow {
    color: white !important;
  }

  .dx-item-content .dx-toolbar-item-content {
    flex-flow: columns;
    margin-top: -14px;
  }

  .dx-toolbar-item {
    display: flex;
    flex-flow: column;
    margin-top: -38px;
  }
  .dx-texteditor-container {
    margin-top: 48px;
  }
  .dx-datagrid-header-panel {
    margin-top: 48px;
  }

  .dx-toolbar-after {
    text-align  end;
  }
`;
