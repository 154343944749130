import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { SelectBox, TagBox } from 'devextreme-react';

import CustomStore from 'devextreme/data/custom_store';
import { CircleSpinner } from 'react-spinners-kit';
import { Button } from '../../../../components/Button';
import master from '../../../../services/master';
import { FormGroup } from '../../../../components/FormGroup';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import { Alert } from '../../../../components/Alert';
import { useAuth } from '../../../../hooks/auth';
import { LoadingOverlay } from './styles';

interface DialogCustomProps {
  idProfessional: number;
  open: boolean;
  handleClose(): void;
}

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const RemoveDialog: React.FC<DialogCustomProps> = ({
  idProfessional,
  open,
  handleClose,
}) => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [accessType, setAccessType] = useState(0);
  const [groups, setGroups] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );

  const loadGroups = useCallback(async () => {
    setGroups({
      store: new CustomStore({
        key: 'IDGroupAD',
        loadMode: 'raw',
        load: async () => {
          const professionalGroups = await master.get(
            `/master/professionals/${idProfessional}/groups`,
          );
          return professionalGroups.data.sort((a: any, b: any) => {
            if (a.GroupAD.Name < b.GroupAD.Name) {
              return -1;
            }
            if (a.GroupAD.Name > b.GroupAD.Name) {
              return 1;
            }
            return 0;
          });
        },
      }),
      paginate: true,
    });
  }, [idProfessional]);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  const handleSend = useCallback(async () => {
    setLoading(true);
    if (accessType === 1) {
      // TODO incluir email + adicionar no temp numa mesma chamada
      selectedGroups.forEach(async g => {
        await master.patch('/master/professional-group-ad/remove', {
          IDProfessional: idProfessional,
          IDGroupAD: g,
        });
      });
      await api.post('/api/group-change-logs', {
        IDProfessional: idProfessional,
        IDGroups: selectedGroups.toString(),
        Action: 2,
        IsPermanent: false,
        ExecutedBy: user.userId,
      });
      addToast({
        title: 'Group(s) removed successfully!',
        type: 'success',
      });
      setLoading(false);
      handleClose();
    } else {
      // TODO incluir email + adicionar no temp numa mesma chamada
      await api.post(`/api/send-email/remove`, {
        IDProfessional: idProfessional,
        Groups: selectedGroups,
      });
      selectedGroups.forEach(async g => {
        await master.patch('/master/professional-group-ad/remove', {
          IDProfessional: idProfessional,
          IDGroupAD: g,
        });
      });
      await api.post('/api/group-change-logs', {
        IDProfessional: idProfessional,
        IDGroups: selectedGroups.toString(),
        Action: 2,
        IsPermanent: true,
        ExecutedBy: user.userId,
      });
      addToast({
        title: 'Group(s) removed successfully!',
        type: 'success',
      });
      setLoading(false);
      handleClose();
    }
  }, [accessType, addToast, handleClose, idProfessional, selectedGroups]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">Remove Group(s)</DialogTitle>
      <DialogContent>
        {loading && (
          <div className="loading-overlay">
            <LoadingOverlay>
              <CircleSpinner color="#8b0304" />
            </LoadingOverlay>
          </div>
        )}
        <FormGroup fieldSetLabel="Remove Type">
          <SelectBox
            dataSource={[
              { ID: 1, Name: 'Temporary' },
              { ID: 2, Name: 'Permanent' },
            ]}
            displayExpr="Name"
            valueExpr="ID"
            onValueChanged={e => setAccessType(e.value)}
            stylingMode="outlined"
          />
        </FormGroup>
        <FormGroup fieldSetLabel="Group(s)">
          <TagBox
            searchEnabled
            dataSource={groups}
            displayExpr="GroupAD.Name"
            valueExpr="IDGroupAD"
            onValueChanged={e => setSelectedGroups(e.value)}
            stylingMode="outlined"
          />
        </FormGroup>
        {accessType === 2 && (
          <Alert type="warning">
            DISCLAIMER: If you send this request as a Permanent remove type, an
            email will be sent to support@integrationconsulting.com asking them
            to remove the groups from the professional permanently.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            handleSend();
          }}
          primary
          disabled={loading}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};
