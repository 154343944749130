import { Drawer } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Drawer)`
  position: relative;
`;

export const Actions = styled.div`
  float: right;
  margin-top: 20px;
`;
