import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { DataGrid } from 'devextreme-react';

import { Column, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Container, Actions } from './styles';

import { Button } from '../../../components/Button';
import { BoardBody } from '../../../components/BoardBody';
import master from '../../../services/master';
import { NewGroupDialog } from './NewGroupDialog';
import { RemoveDialog } from './RemoveDialog';

interface DialogCustomProps {
  professionalId: number;
  open: boolean;
  handleClose(): void;
}

export const ProfessionalGroups: React.FC<DialogCustomProps> = ({
  professionalId,
  open,
  handleClose,
}) => {
  const [groups, setGroups] = useState<DataSource>();
  const [openNewGroupDialog, setOpenNewGroupDialog] = useState(false);
  const [openRemoveGroupDialog, setRemoveGroupDialog] = useState(false);

  const loadGroupDetails = useCallback(async () => {
    const groupsStore = new CustomStore({
      key: 'IDGroupAD',
      loadMode: 'raw',
      load: async () => {
        const response = await master.get(
          `/master/professional-group-ad/professional/${professionalId}`,
        );
        return response.data.sort((a: any, b: any) => {
          if (a.GroupAD.Name < b.GroupAD.Name) {
            return -1;
          }
          if (a.GroupAD.Name > b.GroupAD.Name) {
            return 1;
          }
          return 0;
        });
      },
    });

    setGroups(
      new DataSource({
        store: groupsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [professionalId]);

  useEffect(() => {
    loadGroupDetails();
  }, [loadGroupDetails]);

  const handleDialogDeleteClose = useCallback(() => {
    setRemoveGroupDialog(false);
    loadGroupDetails();
  }, [loadGroupDetails]);

  const handleDialogClose = useCallback(() => {
    setOpenNewGroupDialog(false);
    loadGroupDetails();
  }, [loadGroupDetails]);

  return (
    <Container anchor="right" open={open} onClose={handleClose}>
      <Box width={750}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginRight: '15px',
          }}
        >
          <Typography
            variant="h5"
            component="h5"
            style={{
              fontWeight: 500,
              marginTop: '15px',
              marginBottom: '15px',
              marginLeft: '16px',
            }}
          >
            Professional Groups
          </Typography>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button
              style={{ height: '60%', alignSelf: 'center' }}
              onClick={handleDialogClose}
            >
              Refresh
            </Button>
            <Button
              primary
              style={{ height: '60%', alignSelf: 'center' }}
              onClick={() => setOpenNewGroupDialog(true)}
            >
              Add Group(s)
            </Button>
            <Button
              primary
              style={{ height: '60%', alignSelf: 'center' }}
              onClick={() => setRemoveGroupDialog(true)}
            >
              Remove Group(s)
            </Button>
          </div>
        </div>

        <BoardBody>
          <DataGrid dataSource={groups}>
            <SearchPanel visible />
            <Scrolling mode="infinite" />
            <Column dataField="GroupAD.Name" caption="Group Name" />
          </DataGrid>
        </BoardBody>
        <Actions>
          <Button
            onClick={handleClose}
            style={{ marginRight: '16px', marginBottom: '16px' }}
          >
            Close
          </Button>
        </Actions>
      </Box>

      {openNewGroupDialog && (
        <NewGroupDialog
          idProfessional={professionalId}
          open={openNewGroupDialog}
          handleClose={handleDialogClose}
        />
      )}

      {openRemoveGroupDialog && (
        <RemoveDialog
          idProfessional={professionalId}
          open={openRemoveGroupDialog}
          handleClose={handleDialogDeleteClose}
        />
      )}
    </Container>
  );
};
