import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import { ToastContainer } from '../components/ToastContainer';

export interface MessageProps {
  id: number;
  type: 'error' | 'success' | 'info';
  title: string;
  description?: string;
}

interface ToastContextData {
  addToast(toast: Omit<MessageProps, 'id'>): void;
  removeToast(id: number): void;
}
const ToastContext = createContext<ToastContextData>({} as ToastContextData);

export const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<MessageProps[]>([]);

  const addToast = useCallback(({ type, title, description }) => {
    const id = Math.random();

    const toast = {
      id,
      type,
      title,
      description,
    } as MessageProps;

    setMessages(oldMessages => [...oldMessages, toast]);
  }, []);

  const removeToast = useCallback(id => {
    setMessages(oldMessages =>
      oldMessages.filter(message => message.id !== id),
    );
  }, []);

  const providervalue = useMemo(
    () => ({ addToast, removeToast }),
    [addToast, removeToast],
  );

  return (
    <ToastContext.Provider value={providervalue}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

export function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within an AuthProvider');
  }

  return context;
}
