import React from 'react';

import { Container } from './styles';

interface AlertProps {
  type?: 'success' | 'info' | 'error' | 'warning';
}

export const Alert: React.FC<AlertProps> = ({ type = 'info', children }) => {
  return <Container type={type}>{children}</Container>;
};
