import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { DataGrid } from 'devextreme-react';

import { Column, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import { Container, Actions } from './styles';

import { Button } from '../../../components/Button';
import { BoardBody } from '../../../components/BoardBody';
import { ProfessionalLookup } from '../../../components/ProfessionalLookup';
import master from '../../../services/master';

interface DialogCustomProps {
  professionalId: number;
  open: boolean;
  handleClose(): void;
}

export const ProfessionalEvaluations: React.FC<DialogCustomProps> = ({
  professionalId,
  open,
  handleClose,
}) => {
  const [groups, setGroups] = useState<DataSource>();

  const loadGroupDetails = useCallback(async () => {
    const response = await master.get(
      `/master/professionals/${professionalId}/groups`,
    );
    setGroups(
      response.data.sort((a: any, b: any) => {
        if (a.GroupAD.Name < b.GroupAD.Name) {
          return -1;
        }
        if (a.GroupAD.Name > b.GroupAD.Name) {
          return 1;
        }
        return 0;
      }),
    );
  }, [professionalId]);

  useEffect(() => {
    loadGroupDetails();
  }, [loadGroupDetails]);

  return (
    <Container anchor="right" open={open} onClose={handleClose}>
      <Box width={650}>
        <Typography
          variant="h5"
          component="h5"
          style={{
            fontWeight: 500,
            marginTop: '15px',
            marginBottom: '15px',
            marginLeft: '16px',
          }}
        >
          Professional Evaluations
        </Typography>

        <BoardBody>
          <DataGrid dataSource={groups}>
            <SearchPanel visible />
            <Scrolling mode="infinite" />
            <Column dataField="GroupAD.Name" caption="Last Evaluations" />
          </DataGrid>
        </BoardBody>
        <Actions>
          <Button
            onClick={handleClose}
            style={{ marginRight: '16px', marginBottom: '16px' }}
          >
            Close
          </Button>
        </Actions>
      </Box>
    </Container>
  );
};
