import React, { ButtonHTMLAttributes } from 'react';

import { WhisperSpinner } from 'react-spinners-kit';

import { Container } from './styles';

interface ButtonCustomProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  secondary?: boolean;
  loading?: boolean;
}

export const Button: React.FC<ButtonCustomProps> = ({
  children,
  primary,
  secondary,
  loading = false,
  disabled,
  ...rest
}) => (
  <Container
    {...rest}
    primary={primary && !loading}
    secondary={secondary}
    disabled={loading || disabled}
  >
    {children}
    {loading && (
      <WhisperSpinner size={15} backColor="#8b0304" frontColor="#fff" />
    )}
  </Container>
);
