import React, { BaseHTMLAttributes } from 'react';

import { Container } from './styles';

export const BoardBody: React.FC<BaseHTMLAttributes<HTMLDivElement>> = ({
  children,
  style,
}) => {
  return <Container style={style}>{children}</Container>;
};
