import { Drawer } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Drawer)`
  position: relative;
`;

export const Actions = styled.div`
  float: right;
  margin-top: 20px;
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
`;
