import { IconButton, Tooltip } from '@material-ui/core';
import { format } from 'date-fns';
import DataGrid, {
  HeaderFilter,
  Scrolling,
  Column,
  SearchPanel,
  Editing,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { BoardBody } from '../../components/BoardBody';
import master from '../../services/master';
import { GroupDetails } from './GroupDetails';
import { Container } from './styles';

export const Groups: React.FC = () => {
  const [groups, setGroups] = useState<DataSource>();
  const [groupDetailId, setGroupDetailId] = useState(0);

  const [open, setOpen] = useState(false);

  const loadGroups = useCallback(async () => {
    const groupsStore = new CustomStore({
      key: 'IDGroupAD',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/ad-groups');
        return data.sort((a: any, b: any) => {
          if (a.Name < b.Name) {
            return -1;
          }
          if (a.Name > b.Name) {
            return 1;
          }
          return 0;
        });
      },
      insert: async data => {
        await master.post('/master/ad-groups', data);
      },
    });

    setGroups(
      new DataSource({
        store: groupsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  const boldTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const entryAtCell = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {format(Date.parse(e.text || new Date()), 'MMM. do, yyyy')}
      </p>
    ),
    [],
  );

  const openDetailDialog = useCallback(e => {
    return (
      <Tooltip title="Open details" aria-label="open">
        <IconButton
          aria-label="Open details"
          size="small"
          onClick={() => {
            setGroupDetailId(e.key);
            setOpen(true);
          }}
        >
          <FiExternalLink size={22} color="#8b0304" />
        </IconButton>
      </Tooltip>
    );
  }, []);

  const onToolbarPreparing = useCallback(e => {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach((item: any) => {
      if (item.name === 'addRowButton') {
        item.showText = 'always';
        item.options = {
          ...item.options,
          text: 'NEW GROUP',
        };
      }
    });
  }, []);

  return (
    <Container>
      <BoardBody>
        <DataGrid
          keyExpr="id"
          hoverStateEnabled
          dataSource={groups}
          onCellClick={e => {
            if (e.rowType === 'data') {
              setGroupDetailId(e.key);
              setOpen(true);
            }
          }}
          onToolbarPreparing={onToolbarPreparing}
          onRowUpdating={options => {
            options.newData = { ...options.oldData, ...options.newData };
          }}
        >
          <SearchPanel visible placeholder="Search by name" />
          <HeaderFilter visible allowSearch />
          <Editing allowAdding />
          <Scrolling mode="infinite" />
          <Column dataField="Name" cellRender={boldTextCells} />
          <Column
            dataField="NumberOfProfessionals"
            cellRender={boldTextCells}
            width={230}
            alignment="center"
          />
          <Column
            dataField="CreatedAt"
            allowHeaderFiltering={false}
            allowEditing={false}
            cellRender={entryAtCell}
          />
          <Column cellRender={openDetailDialog} width={60} alignment="right" />
        </DataGrid>
      </BoardBody>

      {open && (
        <GroupDetails
          groupId={groupDetailId}
          open={open}
          handleClose={() => setOpen(false)}
        />
      )}
    </Container>
  );
};
