/* eslint-disable */
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Paging,
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import { WhisperSpinner } from 'react-spinners-kit';
import master, {
  IProfessionalProjectProps,
  IProfessionalProps,
} from '../../services/master';
import BrazilIcon from '../../assets/images/brazil-icon.svg';
import UKIcon from '../../assets/images/uk-icon.svg';
import ChileIcon from '../../assets/images/chile-icon.svg';
import ArgentinaIcon from '../../assets/images/argentina-icon.svg';
import MexicoIcon from '../../assets/images/mexico-icon.svg';
import GermanyIcon from '../../assets/images/germany-icon.svg';
import USAIcon from '../../assets/images/usa-icon.svg';
import { BoardBody } from '../../components/BoardBody';
import { Button } from '../../components/Button';
import { ProfessionalGroups } from './ProfessionalGroups';
import { ProfessionalEvaluations } from './ProfessionalEvaluations';
import { Container, ContainerLoading, Content } from './styles';
import { ModalPage } from '../../components/ModalPage';
import api from '../../services/api';
import { format } from 'date-fns';

interface OfficeProps {
  [prop: string]: string;
}

interface IntegrationSystemProps {
  id: number;
  name: string;
  logo: string;
  isActive: true;
}

interface LastLoginProps {
  integrationSystem: IntegrationSystemProps;
  signInAt: string;
}

export const ProfessionalDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const officeIcons = useMemo<OfficeProps>(
    () => ({
      Brazil: BrazilIcon,
      UK: UKIcon,
      Chile: ChileIcon,
      Argentina: ArgentinaIcon,
      Germany: GermanyIcon,
      Mexico: MexicoIcon,
      USA: USAIcon,
    }),
    [],
  );

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [login, setLogin] = useState('');
  const [title, setTitle] = useState('');
  const [practice, setPractice] = useState('');
  const [promotion, setPromotion] = useState(0);
  const [birthday, setBirthday] = useState(0);
  const [entry, setEntry] = useState(0);
  const [flag, setFlag] = useState('');
  const [lastLogin, setLastLogin] = useState({} as LastLoginProps);

  const [systemsAccess, setSystemsAccess] = useState<DataSource>();
  const [projects, setProjects] = useState<DataSource>();

  const [openGroups, setOpenGroups] = useState(false);
  const [openEvaluations, setOpenEvaluations] = useState(false);

  const loadProfessional = useCallback(async () => {
    setLoading(true);
    const { data } = await master.get<IProfessionalProps>(
      `master/professionals/${id}`,
    );

    const systemsAccessStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const projectsResponse = await api.get(
          `api/professional-sign-in/professional/${id}`,
        );

        setLastLogin(projectsResponse.data[0]);

        return projectsResponse.data;
      },
    });

    const ProjectsStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const projectsResponse = await master.get<IProfessionalProjectProps[]>(
          `master/professionals/${id}/projects`,
        );

        return projectsResponse.data
          .filter(project => new Date(project.Project.EndDate) >= new Date())
          .map(project => ({
            ID: project.ID,
            Code: project.Project.Code,
            CreatedAt: project.CreatedAt,
          }));
      },
    });
    setLoading(false);

    setName(data.Name);
    setLogin(data.Login);
    setBirthday(data.Birthday);
    setEmail(data.Email);
    setPromotion(data.LastPromotion);
    setEntry(data.EntryAt);
    setTitle(data.JobTitle.Name);
    setPractice(data.Practices[0]?.Name);
    setFlag(data.Offices[0]?.Name);
    setSystemsAccess(
      new DataSource({
        store: systemsAccessStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
    setProjects(
      new DataSource({
        store: ProjectsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [id]);

  useEffect(() => {
    loadProfessional();
  }, [loadProfessional]);

  const boldCell = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const entryAtCell = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {format(Date.parse(e.text || new Date()), 'dd-MMM-yyyy')}
      </p>
    ),
    [],
  );

  return (
    <Container>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={50} backColor="#8b0304" />
        </ContainerLoading>
      )}
      <header>
        <div>
          <img
            src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${login}_192_192.png`}
            alt="Employee"
          />
          <h1>{name}</h1>
          <div className="last-login">
            <p className="last-login-p">
              Last login:{' '}
              <b style={{ color: '#333' }}>
                {(lastLogin &&
                  lastLogin.integrationSystem &&
                  lastLogin.integrationSystem.name) ||
                  'No system'}
              </b>{' '}
              at{' '}
              <b style={{ color: '#333' }}>
                {new Date(
                  Date.parse(lastLogin && lastLogin.signInAt),
                ).toLocaleDateString('en-US', {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                })}
              </b>
            </p>
          </div>
          <div className="professional-box">
            <img className="flag" src={officeIcons[flag]} alt="CountryFlag" />
            <div>
              <p>{title}</p>
              <p>{practice}</p>
            </div>
          </div>
        </div>

        <div className="wrapper-prof">
          <div className="professional-resume">
            <div>
              <span className="style-div-prof">
                Working since:
                <p className="rich-class">
                  {entry
                    ? (() => {
                        const date = new Date(entry);
                        date.setDate(date.getDate() + 1); // Add one day
                        return new Intl.DateTimeFormat('pt-BR').format(date);
                      })()
                    : '-'}
                </p>
              </span>
              <span className="style-div-prof">
                Last Promotion:
                <p className="rich-class">
                  {promotion
                    ? new Intl.DateTimeFormat('pt-BR').format(
                        new Date(promotion),
                      )
                    : '-'}
                </p>
              </span>
            </div>

            <div>
              <span className="style-div-prof">
                Birthday:
                <p className="rich-class">
                  {birthday
                    ? (() => {
                        const date = new Date(birthday);
                        const isMidnightUTC =
                          date.getUTCHours() === 0 &&
                          date.getUTCMinutes() === 0 &&
                          date.getUTCSeconds() === 0;

                        if (isMidnightUTC) {
                          // Adjust the date to GMT timezone
                          const adjustedDate = new Date(
                            Date.UTC(
                              date.getUTCFullYear(),
                              date.getUTCMonth(),
                              date.getUTCDate() + 1,
                            ),
                          );
                          return adjustedDate.toLocaleDateString('en-US', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                          });
                        } else {
                          return date.toLocaleDateString('en-US', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                          });
                        }
                      })()
                    : '-'}
                </p>
              </span>
              <span className="style-div-prof">
                Email:
                <p className="rich-class">{email || 'E-mail não cadastrado'}</p>
              </span>
            </div>
          </div>
        </div>

        <div className="wrapper">
          <div className="group-box">
            <Button onClick={() => setOpenGroups(true)}>Groups</Button>
          </div>

          <ModalPage professionalId={Number(id)} />
        </div>
      </header>

      <Content>
        <div style={{ alignSelf: 'start', marginBottom: '24px' }}>
          <div className="int-box">
            Systems and Access
            <hr className="red-separator" />
          </div>
          <BoardBody>
            <DataGrid
              showBorders={false}
              showColumnHeaders
              hoverStateEnabled
              keyExpr="id"
              dataSource={systemsAccess}
            >
              <Grouping contextMenuEnabled />
              <GroupPanel visible />
              <HeaderFilter visible allowSearch />
              <Paging pageSize={15} />
              <Column
                dataField="integrationSystem.name"
                caption="System"
                cellRender={boldCell}
              />
              <Column
                allowGrouping={false}
                dataField="signInAt"
                caption="Sign In Date"
                allowHeaderFiltering={false}
                cellRender={entryAtCell}
              />
            </DataGrid>
          </BoardBody>
        </div>

        <div style={{ alignSelf: 'start', marginBottom: '24px' }}>
          <div className="int-box">
            Project
            <hr className="red-separator" />
          </div>
          <BoardBody>
            <DataGrid
              showBorders={false}
              showColumnHeaders
              hoverStateEnabled
              dataSource={projects}
            >
              <HeaderFilter visible allowSearch />
              <Paging pageSize={15} />
              <Column
                dataField="Code"
                caption="Project"
                allowGrouping={false}
              />
              <Column
                dataField="CreatedAt"
                dataType="date"
                format="dd/MM/yyyy"
                caption="Started at"
              />
            </DataGrid>
          </BoardBody>
        </div>
      </Content>

      {openGroups && (
        <ProfessionalGroups
          open={openGroups}
          handleClose={() => setOpenGroups(false)}
          professionalId={Number(id)}
        />
      )}

      {openEvaluations && (
        <ProfessionalEvaluations
          open={openEvaluations}
          handleClose={() => setOpenEvaluations(false)}
          professionalId={Number(id)}
        />
      )}
    </Container>
  );
};
