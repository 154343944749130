import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { DataGrid } from 'devextreme-react';

import { format } from 'date-fns';
import { Column, Paging, SearchPanel } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import { WhisperSpinner } from 'react-spinners-kit';
import { Container, Actions, ContainerLoading } from './styles';

import { Button } from '../../../components/Button';
import { BoardBody } from '../../../components/BoardBody';
import { ProfessionalLookup } from '../../../components/ProfessionalLookup';
import master from '../../../services/master';

interface DialogCustomProps {
  groupId: number;
  open: boolean;
  handleClose(): void;
}

export const GroupDetails: React.FC<DialogCustomProps> = ({
  groupId,
  open,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [groupProfessionals, setGroupProfessionals] = useState<DataSource>();
  const [groupName, setGroupName] = useState('');
  const [groupCreatedAt, setGroupCreatedAt] = useState('');
  const [numberOfProfessionals, setNumberOfProfessionals] = useState(0);

  const loadGroupDetails = useCallback(async () => {
    setLoading(true);
    const response = await master.get(`/master/ad-groups/${groupId}`);
    const responseProfessionals = await master.get(
      `/master/ad-groups/${groupId}/professionals`,
    );
    setGroupName(response.data.Name);
    setGroupCreatedAt(response.data.CreatedAt);
    setGroupProfessionals(
      responseProfessionals.data.sort((a: any, b: any) => {
        if (a.Professional.Name < b.Professional.Name) {
          return -1;
        }
        if (a.Professional.Name > b.Professional.Name) {
          return 1;
        }
        return 0;
      }),
    );
    setNumberOfProfessionals(response.data.NumberOfProfessionals);
    setLoading(false);
  }, [groupId]);

  useEffect(() => {
    loadGroupDetails();
  }, [loadGroupDetails]);

  const professionalCell = useCallback(e => {
    return (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() =>
          window.open(
            `/professionals/${e.key.IDProfessional}`,
            `Window${e.key.IDProfessional}`,
          )
        }
        role="none"
      >
        <ProfessionalLookup
          name={e.data.Professional.Name}
          login={e.data.Professional.Login}
          jobtitle=""
          style={{ cursor: 'pointer' }}
        />
      </div>
    );
  }, []);

  return (
    <Container anchor="right" open={open} onClose={handleClose}>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={50} backColor="#8b0304" />
        </ContainerLoading>
      )}
      <Box width={650}>
        <Typography
          variant="h5"
          component="h5"
          style={{
            fontWeight: 500,
            marginTop: '15px',
            marginBottom: '15px',
            marginLeft: '16px',
          }}
        >
          Group details
        </Typography>

        <BoardBody>
          <p style={{ marginBottom: '5px', marginLeft: '16px' }}>
            <b style={{ fontWeight: 'bold', fontSize: '16px' }}>Group name:</b>{' '}
            <b style={{ fontWeight: 500, fontSize: '16px' }}>{groupName}</b>
          </p>
          <p style={{ marginBottom: '5px', marginLeft: '16px' }}>
            <b style={{ fontWeight: 'bold', fontSize: '16px' }}>
              Number of professionals:
            </b>{' '}
            <b style={{ fontWeight: 500, fontSize: '16px' }}>
              {numberOfProfessionals}
            </b>
          </p>
          <p style={{ marginBottom: '15px', marginLeft: '16px' }}>
            <b style={{ fontWeight: 'bold', fontSize: '16px' }}>Created at: </b>
            <b
              style={{
                fontWeight: 500,
                fontSize: '16px',
              }}
            >
              {format(
                Date.parse(groupCreatedAt || new Date().toDateString()),
                'MMM. do, yyyy',
              )}
            </b>
          </p>
          <DataGrid dataSource={groupProfessionals}>
            <SearchPanel visible />
            <Paging pageSize={15} />
            <Column
              dataField="Professional.Name"
              caption="Professional"
              cellRender={professionalCell}
            />
          </DataGrid>
        </BoardBody>
        <Actions>
          <Button
            onClick={handleClose}
            style={{ marginRight: '16px', marginBottom: '16px' }}
          >
            Close
          </Button>
        </Actions>
      </Box>
    </Container>
  );
};
