import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  .kpiDivider {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }

  .dx-datagrid-addrow-button {
    background-color: #8b0304;
    color: white;
  }

  .dx-datagrid-addrow-button.dx-state-hover {
    background-color: #650001;
  }

  .dx-icon-edit-button-addrow {
    color: white !important;
  }

  header {
    display: flex;
    justify-content: space-between;
    padding: 0 35px;

    span {
      display: flex;
    }

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;

      h1 {
        font-size: 20px;
        color: #aaaaaa;
        font-weight: 700;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }

      .requiredArea {
        padding-left: 15px;
        align-items: center;

        p {
          font-weight: 700;
          letter-spacing: 0.1em;
        }
      }
    }

    .updateArea {
      align-items: center;

      p {
        padding: 0 3px;
        font-weight: 500;
        letter-spacing: 0.1em;
      }
    }
  }

  hr {
    margin: 0;
    border-top: 0;
    border-left: 0;
    border-bottom: 2px solid #dddddd;
  }

  .row {
    width: 99%;
    display: flex;
    justify-content: space-evenly;
    padding: 10px 0;
    padding-left: 5px;

    .col {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 25px;

      .MuiTypography-root {
        text-align: left !important;
      }

      .MuiListItem-root {
        padding: 0;
      }

      span {
        display: flex;
        gap: 15px;

        p {
          font-weight: 700;
        }
      }
    }

    .checkboxCol {
      display: flex;
      padding-left: 0;
      flex-direction: row;
      gap: 15px;
    }

    .barContainer {
      padding: 0;
      padding-top: 5px;
      margin: 0 15px;

      p {
        color: #c9c9c9;
        text-align: center;
        font-weight: 700;
      }
    }
  }

  .salesKPI {
    width: 95%;
    margin: 0 auto;
    text-align: center;
    border-left: 15px solid #6f6485;
    padding-left: 15px;

    h3 {
      color: #6f6485;
      font-size: 16px;
      font-weight: 700;
    }

    .col {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 8px;
      margin: 0 5px;
      background-color: #e4e4e4;
      border-radius: 15px;
      min-height: 230px;

      p {
        font-weight: 700;
        text-align: center;
      }

      h1 {
        color: #463e3d;
        font-size: 60px;
        font-weight: 700;
      }

      .description {
        text-align: left;
        background-color: #d2d2d2;
        border-radius: 10px;
        width: 80%;
        margin: auto;
        padding: 15px 5px;
        margin-top: 5px;

        span {
          display: flex;
          align-items: baseline;

          p {
            margin-left: 1px;
            font-size: 14px;
            color: #463e3d;
            font-weight: normal;
          }
        }
      }

      .yesorno {
        color: #e3312b;
        font-size: 80px;
        text-transform: lowercase;
        letter-spacing: 0;
      }

      .exclamation {
        color: white;
        background-color: #049644;
        font-size: 40px;
        line-height: 40px;
        height: 40px;
        width: 40px;
        font-weight: 700;
        border-radius: 50%;
        align-self: center;
      }
    }
  }

  .otcKPI {
    width: 95%;
    margin: 0 auto;
    text-align: center;
    border-left: 15px solid #683354;
    padding-left: 15px;

    h3 {
      color: #683354;
      font-size: 16px;
      font-weight: 700;
    }

    .col {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 8px;
      margin: 0 5px;
      background-color: #e4e4e4;
      border-radius: 15px;
      min-height: 230px;

      p {
        font-weight: 700;
        text-align: center;
      }

      h1 {
        letter-spacing: 0;
        color: #463e3d;
        font-size: 60px;
        font-weight: 700;
      }

      .description {
        text-align: left;
        background-color: #d2d2d2;
        border-radius: 10px;
        width: 80%;
        margin: auto;
        padding: 15px 5px;
        margin-top: 5px;

        span {
          display: flex;
          align-items: baseline;
          line-height: 20px;

          p {
            margin-left: 1px;
            font-size: 15px;
            color: #463e3d;
            font-weight: normal;
          }
        }
      }

      span {
        display: inline-flex;
        align-items: center;
        margin: 0 auto;
      }

      .yesorno {
        color: #e3312b;
        font-size: 80px;
        text-transform: lowercase;
        letter-spacing: 0;
        padding-top: 5%;
      }

      .exclamation {
        color: white;
        margin-top: 20px;
        background-color: #049644;
        font-size: 40px;
        line-height: 40px;
        width: 40px;
        font-weight: 700;
        border-radius: 50%;
      }
    }
  }

  .legalKPI {
    width: 95%;
    margin: 0 auto;
    text-align: center;
    border-left: 15px solid #a82d49;
    padding-left: 15px;

    h3 {
      color: #a82d49;
      font-size: 16px;
      font-weight: 700;
    }

    .col {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 8px;
      margin: 0 5px;
      background-color: #e4e4e4;
      border-radius: 15px;
      min-height: 230px;

      p {
        font-weight: 700;
        text-align: center;
      }

      h1 {
        letter-spacing: 0;
        color: #463e3d;
        font-size: 60px;
        font-weight: 700;
      }

      .description {
        text-align: left;
        background-color: #d2d2d2;
        border-radius: 10px;
        width: 80%;
        margin: auto;
        padding: 15px 5px;
        margin-top: 5px;

        span {
          display: flex;
          align-items: baseline;
          line-height: 20px;

          p {
            margin-left: 1px;
            font-size: 15px;
            color: #463e3d;
            font-weight: normal;
          }
        }
      }

      span {
        display: inline-flex;
        align-items: center;
        margin: 0 auto;
      }

      .yesorno {
        color: #e3312b;
        font-size: 80px;
        text-transform: lowercase;
        letter-spacing: 0;
        padding-top: 5%;
      }

      .exclamation {
        color: white;
        margin-top: 20px;
        background-color: #049644;
        font-size: 40px;
        line-height: 40px;
        width: 40px;
        font-weight: 700;
        border-radius: 50%;
      }
    }
  }
`;

export const Section = styled.section`
  margin-bottom: 50px;
  width: 100%;

  h1 {
    padding: 0 35px;
    font-size: 20px;
    color: #aaaaaa;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
`;

export const SectionTitle = styled.div`
  padding: 0 30px;
  padding-bottom: 5px;
  font-size: 20px;
  color: #aaaaaa;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 10px;
  border-bottom: 2px solid #dddddd;
`;

export const ContainerLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #efefef;
  height: 100vh;
`;

export const Bar = styled.div`
  border-radius: 15px;
  width: 40%;
  height: 5px;
  margin: 0 auto;
`;

export const BarActive = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 8px;
  background-color: #c9c9c9;
`;
