import { format } from 'date-fns';
import DataGrid, {
  HeaderFilter,
  Scrolling,
  Column,
  Editing,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useState } from 'react';
import { BoardBody } from '../../components/BoardBody';
import { BoardHeader } from '../../components/BoardHeader';
import master from '../../services/master';
import { Container } from './styles';

export const Geographies: React.FC = () => {
  const [geographic, setGeographic] = useState<DataSource>();
  const [geographicDetailId, setGeographicDetailId] = useState(0);

  const [open, setOpen] = useState(false);

  const loadGeographics = useCallback(async () => {
    const geographicStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/geographics');
        return data.sort((a: any, b: any) => {
          if (a.Name < b.Name) {
            return -1;
          }
          if (a.Name > b.Name) {
            return 1;
          }
          return 0;
        });
      },
      insert: async data => {
        await master.post('/master/geographics', data);
      },
      update: async (id, data) => {
        await master.put(`/master/geographics/${id}`, {
          Name: data.Name,
          IsActive: data.IsActive,
          Abbreviation: data.Abbreviation,
          IsState: data.IsState,
        });
      },
    });

    setGeographic(
      new DataSource({
        store: geographicStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadGeographics();
  }, [loadGeographics]);

  const boldTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const entryAtCell = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {format(Date.parse(e.text || new Date()), 'MMM. do, yyyy')}
      </p>
    ),
    [],
  );

  return (
    <Container>
      <BoardHeader title="Geographies" subtitle="Geographies" />
      <BoardBody>
        <DataGrid
          keyExpr="id"
          dataSource={geographic}
          onCellClick={e => {
            if (e.rowType === 'data') {
              setGeographicDetailId(e.key);
              setOpen(true);
            }
          }}
        >
          <Editing allowAdding allowUpdating />
          <HeaderFilter visible allowSearch />
          <Scrolling mode="infinite" />
          <Column dataField="Name" cellRender={boldTextCells} />
          <Column
            dataField="CreatedAt"
            allowHeaderFiltering={false}
            allowEditing={false}
            cellRender={entryAtCell}
          />
          <Column
            dataField="Abbreviation"
            caption="Abbreviation"
            // visible={false}
          />
          <Column dataField="IsState" caption="IsState" />

          <Column dataField="IsActive" />
        </DataGrid>
      </BoardBody>
    </Container>
  );
};
