import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  background-color: #efefef;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  left: 80px;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: left 100ms ease;
  -o-transition: left 100ms ease;
  transition: left 100ms ease;
  overflow: hidden;
  border-radius: 0;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  width: 100%;
  opacity: 1;
  visibility: visible;

  -webkit-transition: margin-left 200ms ease, visibility 200ms ease,
    opacity 200ms ease;
  -o-transition: margin-left 200ms ease, visibility 200ms ease,
    opacity 200ms ease;
  transition: margin-left 200ms ease, visibility 200ms ease, opacity 200ms ease;
`;

export const Content = styled.div`
  overflow: auto;
  overflow-x: hidden;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const Board = styled.div`
  bottom: 50px;
  top: 0;
  position: absolute;
  width: 100%;
  overflow: auto;
`;
