import styled from 'styled-components';

export const Container = styled.h1`
  font-size: 36px;
  font-weight: 700;
  color: #8b0305;

  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
`;

export const Separator = styled.hr`
  width: 56px;
  border-top: 8px solid #8b0305;
  border-radius: 2px;
`;
