import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 64px;

  .dx-row {
    cursor: pointer;
  }

  .dx-datagrid-addrow-button {
    background-color: #8b0304;
    color: white;
  }

  .dx-datagrid-addrow-button.dx-state-hover {
    background-color: #650001;
  }

  .dx-icon-edit-button-addrow {
    color: white !important;
  }
`;
