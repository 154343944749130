import React from 'react';
import { useHistory } from 'react-router-dom';

export const Dashboard: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <h1
        style={{
          textAlign: 'center',
          marginTop: '20%',
          fontSize: '24px',
          fontWeight: 600,
          fontStyle: 'italic',
        }}
      >
        To be done...
      </h1>
      <p
        style={{
          width: 'fit-content',
          margin: '0 auto',
          color: 'blue',
          textDecoration: 'underline',
          fontWeight: 400,
          fontSize: '14px',
          cursor: 'pointer',
        }}
        onClick={() => history.push('/')}
        role="none"
      >
        Return to Home
      </p>
    </>
  );
};
