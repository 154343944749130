import styled, { css } from 'styled-components';

interface AlertProps {
  type: 'success' | 'info' | 'error' | 'warning';
}
export const Container = styled.div<AlertProps>`
  width: 100%;
  padding: 18px;
  margin: 15px 0px;
  cursor: pointer;
  color: #333;
  font-weight: 700;
  border-radius: 5px;
  font-size: 14px;

  ${props =>
    props.type === 'success' &&
    css`
      background-color: #4cb56f;
      border: 1px solid #4cb56f;
    `}

  ${props =>
    props.type === 'info' &&
    css`
      background-color: rgb(82, 185, 217);
      border: 1px solid rgb(82, 185, 217);
    `}

  ${props =>
    props.type === 'error' &&
    css`
      background-color: rgb(209, 92, 86);
      border: 1px solid rgb(209, 92, 86);
    `}

  ${props =>
    props.type === 'warning' &&
    css`
      background-color: rgb(237, 206, 97);
      border: 1px solid rgb(237, 206, 97);
    `}
`;
