import React from 'react';
import { Container, Separator } from './styles';
import { AdminButton } from '../../components/AdminButton';

export const Admin: React.FC = () => {
  return (
    <>
      <Container>ADMIN AREA</Container>
      <Separator />
      <AdminButton />
    </>
  );
};
