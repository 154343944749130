import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';

interface DocumentTitleContextData {
  setTitle(title: string | undefined): void;
  setDefaultTitle(): void;
  getTitle(): string;
}

const DocumentTitleContext = createContext<DocumentTitleContextData>(
  {} as DocumentTitleContextData,
);

export const DocumentTitleProvider: React.FC = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('');

  const setTitle = useCallback(title => {
    if (title) {
      document.title = `${title} - ITC`;
      window.name = `Window${title}`;
      setPageTitle(title);
    } else {
      document.title = `ITC`;
      window.name = `WindowITC`;
      setPageTitle('ITC');
    }
  }, []);

  const setDefaultTitle = useCallback(() => {
    document.title = `ITC`;
    window.name = `WindowITC`;
  }, []);

  const getTitle = useCallback(() => document.title, []);

  const providerValue = useMemo(
    () => ({
      setDefaultTitle,
      setTitle,
      getTitle,
    }),
    [setDefaultTitle, setTitle, getTitle],
  );

  return (
    <DocumentTitleContext.Provider value={providerValue}>
      {children}
    </DocumentTitleContext.Provider>
  );
};

export function useDocumentTitle(): DocumentTitleContextData {
  const context = useContext(DocumentTitleContext);

  if (!context) {
    throw new Error('useToast must be used within an DocumentTitleContext');
  }

  return context;
}
