import { Box, Modal, Typography } from '@material-ui/core';
import CustomStore from 'devextreme/data/custom_store';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  SearchPanel,
} from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';
import { WhisperSpinner } from 'react-spinners-kit';
import { Button } from '../Button';
import { BoardBody } from '../BoardBody';

import master from '../../services/master';
import { Container } from './styles';
import api from '../../services/api';
import { ContainerLoading } from '../ClientInfo/styles';

interface IProfessionalStore {
  store: CustomStore;
  paginate: boolean;
}

interface ModalPageProps {
  professionalId?: number;
}

export const ModalPage: React.FC<ModalPageProps> = ({ professionalId }) => {
  const [open, setOpen] = useState(false);
  const [professionalIdOne, setProfessionalIdOne] = useState(
    professionalId || 0,
  );
  const [professionalIdTwo, setProfessionalIdTwo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [compare, setCompare] = useState<IProfessionalStore>(
    {} as IProfessionalStore,
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute' as const,
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    height: '93vh',
    bgcolor: '#EEEEEE',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const [professional, setProfessionals] = useState<IProfessionalStore>(
    {} as IProfessionalStore,
  );

  const loadProfessional = useCallback(async () => {
    const ProfessionalStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/professionals', {
          params: {
            where: {
              IsAnyStatus: true,
            },
          },
        });
        return data;
      },
    });
    setProfessionals({
      store: ProfessionalStore,
      paginate: true,
    });
  }, []);

  useEffect(() => {
    loadProfessional();
  }, [loadProfessional]);

  const handleCompare = useCallback(async () => {
    const CompareStore = new CustomStore({
      key: 'groupID',
      loadMode: 'raw',
      load: async () => {
        setLoading(true);
        const response = await api.patch(
          `/api/professional-group-ad/compare/professional1/${professionalIdOne}/professional2/${professionalIdTwo}`,
        );
        setLoading(false);
        return response.data;
      },
    });
    setCompare({
      store: CompareStore,
      paginate: true,
    });
  }, [professionalIdOne, professionalIdTwo]);

  return (
    <Container>
      <Button onClick={handleOpen} className="button-style">
        Compare
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '20px ',
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <SelectBox
                dataSource={professional}
                valueExpr="ID"
                defaultValue={professionalId}
                onValueChanged={e => setProfessionalIdOne(e.value)}
                displayExpr="Name"
                width={420}
                searchEnabled
              />
            </Typography>

            <Typography id="modal-modal-title" variant="h6" component="h2">
              <SelectBox
                dataSource={professional}
                valueExpr="ID"
                onValueChanged={e => setProfessionalIdTwo(e.value)}
                displayExpr="Name"
                width={420}
                searchEnabled
              />
            </Typography>

            <Button
              primary
              style={{ width: '15%', placeContent: 'center' }}
              onClick={handleCompare}
            >
              Compare
            </Button>
          </div>
          {loading && (
            <ContainerLoading>
              <WhisperSpinner size={50} backColor="#8b0304" />
            </ContainerLoading>
          )}
          {!loading && (
            <BoardBody>
              <DataGrid
                id="mock"
                dataSource={compare}
                style={{ height: '99%' }}
                keyExpr="GroupName"
              >
                <HeaderFilter visible />
                <SearchPanel
                  visible
                  placeholder="Search by name"
                  width={1120}
                />
                <Column dataField="groupName" />
                <Column
                  dataField="professional1HasGroup"
                  caption="Professional 1 Has Access"
                />
                <Column
                  dataField="professional2HasGroup"
                  caption="Professional 2 Has Access"
                />

                <Paging pageSize={6} />
              </DataGrid>
            </BoardBody>
          )}
        </Box>
      </Modal>
    </Container>
  );
};
