import styled from 'styled-components';

export const Container = styled.div`
  width: 100%
  height: 280px;


  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    background-color: #fff;
  }

  img {
    max-width: 88px;
    height: 88px;
    border-radius: 24px;
    margin-bottom: 12px;
  }
  h1 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 10px;
  }
  p {
    font-weight: 700;
    font-size: 18px;
    color: #c8c8c8;
  }

  .flag {
    max-width: 48px;
    height: 48px;
  }

  .last-login {
    margin-bottom: 10px;
  }

  .last-login-p {
    font-size: 14px;
    font-style: italic;
  }

  .professional-box {
    width: 100%;
    display: flex;
    gap: 14px;
  }

  .wrapper {
    display: flex;
  }

  .evaluator {
    max-width: 52px;
    height: 52px;
    margin: 0 auto;
  }

  .grp-button {
    border: 2px solid;
    background-color: #8b0305;
    cursor: pointer;
    padding: 8px;
  }

  .group-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }


  .group-title {
    align-self: center;
  }

  .professional-resume {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .wrapper-prof {
    display: flex;
    align-self: flex-end;
    margin-left: 64px;
  }

  .style-div-prof {
    background-color: #f0f0f0
    border-radius: 2px; 
    display: flex;
    font-weight: 700;
    font-size: 16px;
    color: #333333;
    padding: 18px;
  }


  .rich-class {
    margin-left: 6px;
    text-align: center;
    font-size: 18px;
    color: #8b0305
  }
`;

export const Content = styled.div`
  margin-left: 64px;
  width: 90%;
  .int-box {
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    margin-top: 32px;
    align-content: top;
  }

  .red-separator {
    width: 56px;
    border-top: 8px solid #8b0305;
    border-radius: 2px;
  }
`;

export const ContainerLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #efefef;
  height: 100vh;
`;
