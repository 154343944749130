import styled from 'styled-components';

export const Container = styled.fieldset`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  input {
    border: none;
    border-radius: 5px;
    padding: 10px !important;
    font-size: 14px;
    background-color: #dedede;
    color: #333;
    font-family: 'Roboto Condensed', sans-serif;
    width: 100%;
  }

  .italic {
    margin-left: 5px;
    color: '#9379fd';
    font-style: 'italic';
  }

  label {
    display: flex;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1em;
  }
`;

export const Label = styled.label`
  display: flex;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
`;
