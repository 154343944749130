import React, { useCallback } from 'react';
import {
  FiUsers,
  FiSettings,
  FiUser,
  FiLayout,
  FiBarChart2,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Container, Buttons } from './styles';
import itcGroups from '../../config/itcGroups';
import { useAuth } from '../../hooks/auth';

export const HomeButton: React.FC = () => {
  const history = useHistory();

  const { hasScopes } = useAuth();

  const handleProfessional = useCallback(() => {
    history.push('/professionals');
  }, [history]);

  const handleSystems = useCallback(() => {
    history.push('/systems');
  }, [history]);

  const handleGroups = useCallback(() => {
    history.push('/groups');
  }, [history]);

  const handleAdmin = useCallback(() => {
    history.push('/admin');
  }, [history]);

  const handleDashboard = useCallback(() => {
    history.push('/dashboard');
  }, [history]);

  return (
    <Container>
      <Buttons onClick={handleProfessional}>
        <FiUser size="24" style={{ margin: '5px' }} />
        PROFESSIONALS
      </Buttons>
      <Buttons onClick={handleSystems}>
        <FiLayout size="24" style={{ margin: '5px' }} />
        SYSTEMS
      </Buttons>
      <Buttons onClick={handleGroups}>
        <FiUsers size="24" style={{ margin: '5px' }} />
        GROUPS
      </Buttons>
      <Buttons onClick={handleDashboard}>
        <FiBarChart2 size="24" style={{ margin: '5px' }} />
        DASHBOARD
      </Buttons>
      {hasScopes([itcGroups.Admin]) && (
        <Buttons onClick={handleAdmin}>
          <FiSettings size="24" style={{ margin: '5px' }} />
          ADMIN
        </Buttons>
      )}
    </Container>
  );
};
