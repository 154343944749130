import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 64px;

  .dx-toolbar .dx-texteditor {
    width: 100% !important;
  }

  .dx-toolbar .dx-toolbar-after {
    padding-left: 0px;
    width: 100% !important;
  }

  .dx-datagrid-search-panel {
    margin-left: 0px;
  }
`;

export const Photo = styled.img`
  max-width: 38px;
  border-radius: 50%;
`;

export const PhotoGray = styled.img`
  max-width: 38px;
  border-radius: 50%;
  filter: grayscale(1);

  &:hover {
    filter: none;
  }
`;
