import React, { useCallback, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';

import queryString from 'query-string';
import { WhisperSpinner } from 'react-spinners-kit';

import { useAuth } from '../../hooks/auth';

import { Container } from './styles';

export const SignIn: React.FC = (props: RouteProps) => {
  const { redirectMicrosoft, signInAuthorize } = useAuth();

  const signIn = useCallback(
    async accessToken => {
      try {
        await signInAuthorize(accessToken);

        // Needs to redirect to get localStorage
        window.location.href = '/app';
      } catch (err) {
        const errorMessage = 'Error on sign in';
        // history.replace('/', { error: errorMessage });
      }
    },
    [signInAuthorize],
  );

  useEffect(() => {
    // eslint-disable-next-line react/destructuring-assignment
    const params = queryString.parse(props.location?.hash || '');

    if (params.access_token) {
      signIn(params.access_token);
    } else {
      redirectMicrosoft();
    }
  }, [redirectMicrosoft, signIn, props]);

  return (
    <Container>
      <WhisperSpinner size={80} backColor="#8b0304" />
    </Container>
  );
};
