import React, { FieldsetHTMLAttributes } from 'react';
import { Container } from './styles';

interface ProfessionalLookupProps
  extends FieldsetHTMLAttributes<HTMLFieldSetElement> {
  name: string;
  login: string;
  jobtitle: string;
}

export const ProfessionalLookup: React.FC<ProfessionalLookupProps> = ({
  name,
  login,
  jobtitle,
}) => {
  return (
    <Container>
      {login && (
        <img
          src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${login}_192_192.png`}
          alt={name}
        />
      )}

      <div>
        <p className="name">{name}</p>
        <p>{jobtitle}</p>
      </div>
    </Container>
  );
};
