import React from 'react';
import { Container, Separator } from './styles';
import { HomeButton } from '../../components/HomeButton';

export const Home: React.FC = () => {
  return (
    <>
      <Container>WELCOME TO THE ITC SYSTEM!</Container>
      <Separator />
      <HomeButton />
    </>
  );
};
