import React, { FieldsetHTMLAttributes } from 'react';

import { Container, Label } from './styles';

interface FormGroupProps extends FieldsetHTMLAttributes<HTMLFieldSetElement> {
  fieldSetLabel?: string;
  isRequired?: boolean;
}

export const FormGroup: React.FC<FormGroupProps> = ({
  children,
  fieldSetLabel,
  isRequired,
}) => {
  return (
    <Container>
      {fieldSetLabel && (
        <Label>
          {fieldSetLabel} {isRequired && <p style={{ color: '#8B0304' }}>*</p>}
        </Label>
      )}

      {children}
    </Container>
  );
};
