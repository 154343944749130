import { format } from 'date-fns';
import DataGrid, {
  HeaderFilter,
  Scrolling,
  Column,
  Editing,
  Lookup,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useState } from 'react';
import { BoardBody } from '../../components/BoardBody';
import { BoardHeader } from '../../components/BoardHeader';
import master from '../../services/master';
import { Container } from './styles';

export const IntegrationGroups: React.FC = () => {
  const [iGroups, setIGroups] = useState<DataSource>();

  const [iGroupsDetailId, setIGroupsDetailId] = useState();

  const [open, setOpen] = useState(false);

  const loadIGroups = useCallback(async () => {
    const iGroupsStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await master.get('/master/integration-groups');
        return data;
      },
      insert: async data => {
        await master.post('/master/integration-groups', data);
      },
      update: async (id, data) => {
        await master.put(`/master/integration-groups/${id}`, {
          Name: data.Name,
          IsActive: data.IsActive,
        });
      },
    });

    setIGroups(
      new DataSource({
        store: iGroupsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadIGroups();
  }, [loadIGroups]);

  const boldTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const entryAtCell = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {format(Date.parse(e.text || new Date()), 'MMM. do, yyyy')}
      </p>
    ),
    [],
  );

  return (
    <Container>
      <BoardHeader title="Integration Groups" subtitle="Integration Groups" />
      <BoardBody>
        <DataGrid
          keyExpr="id"
          dataSource={iGroups}
          onCellClick={e => {
            if (e.rowType === 'data') {
              setIGroupsDetailId(e.key);
              setOpen(true);
            }
          }}
        >
          <Editing allowAdding allowUpdating />
          <HeaderFilter visible allowSearch />
          <Scrolling mode="infinite" />
          <Column dataField="Name" cellRender={boldTextCells} />
          <Column
            dataField="CreatedAt"
            allowHeaderFiltering={false}
            allowEditing={false}
            cellRender={entryAtCell}
          />

          <Column
            dataField="IsActive"
            allowHeaderFiltering={false}
            dataType="boolean"
            caption="Is Active?"
            cellRender={boldTextCells}
          />
        </DataGrid>
      </BoardBody>
    </Container>
  );
};
