import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import CustomStore from 'devextreme/data/custom_store';
import { WhisperSpinner } from 'react-spinners-kit';
import { CheckBox } from 'devextreme-react';
import master, { ISystemProps } from '../../../services/master';
import BoardIcon from '../../../assets/images/board.png';
import CRMIcon from '../../../assets/images/crm.png';
import InPlaceIcon from '../../../assets/images/inplace.png';
import LegalIcon from '../../../assets/images/legal.png';
import LettersIcon from '../../../assets/images/letters.png';
import PDSIcon from '../../../assets/images/pds.png';
import PortalIcon from '../../../assets/images/portal.png';
import RecruitmentIcon from '../../../assets/images/recruitment.png';
import SalesIcon from '../../../assets/images/sales.png';
import SchedulingIcon from '../../../assets/images/scheduling.png';
import UploadIcon from '../../../assets/images/upload.png';
import WeeklyIcon from '../../../assets/images/weekly.png';
import NoIcon from '../../../assets/images/no-icon.svg';
import { BoardBody } from '../../../components/BoardBody';
import { Container, ContainerLoading, Content } from './styles';
import api from '../../../services/api';
import { ProfessionalLookup } from '../../../components/ProfessionalLookup';

interface OfficeProps {
  [prop: string]: string;
}

export const SystemDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const systemIcons = useMemo<OfficeProps>(
    () => ({
      board: BoardIcon,
      crm: CRMIcon,
      inplace: InPlaceIcon,
      legal: LegalIcon,
      letter: LettersIcon,
      pds: PDSIcon,
      portal: PortalIcon,
      recruitment: RecruitmentIcon,
      sales: SalesIcon,
      scheduling: SchedulingIcon,
      upload: UploadIcon,
      weekly: WeeklyIcon,
      itc: NoIcon,
    }),
    [],
  );

  const [loading, setLoading] = useState(false);
  const [systemProfessionals, setSystemProfessionals] = useState<DataSource>();
  const [systemLogins, setSystemLogins] = useState<DataSource>();
  const [name, setName] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const loadSystem = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get<ISystemProps>(
      `api/integration-systems/${id}`,
    );
    setName(data.name);
    setIsActive(data.isActive);

    const systemProfessionalsStore = new CustomStore({
      key: 'idProfessional',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get(
          `/api/integration-systems/${id}/professionals`,
        );

        const adGroups = await master.get('/master/ad-groups');

        response.data.map((x: any) => {
          x.groupName = adGroups.data.find(
            (y: any) => y.IDGroupAD === x.idGroupAD,
          ).Name;
        });

        return response.data;
      },
    });

    const systemLoginsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get(`/api/integration-systems/${id}/logins`);

        return response.data.slice(0, 10);
      },
    });

    setSystemProfessionals(
      new DataSource({
        store: systemProfessionalsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );

    setSystemLogins(
      new DataSource({
        store: systemLoginsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );

    setLoading(false);
  }, [id]);

  useEffect(() => {
    loadSystem();
  }, [loadSystem]);

  const greyTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#CCCCCC',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const professionalCell = useCallback(e => {
    return (
      <ProfessionalLookup
        name={e.data.professional.name}
        login={e.data.professional.login}
        jobtitle={e.data.professional.jobTitle.name}
      />
    );
  }, []);

  const signInAtCell = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {format(new Date(e.text), 'MMM. do, yyyy p')}
      </p>
    ),
    [],
  );

  const boldCell = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  return (
    <Container>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={50} backColor="#8b0304" />
        </ContainerLoading>
      )}
      <header>
        <div>
          <img className="logo" src={systemIcons[name]} alt="System logo" />
          <div className="info">
            <h1>{name}</h1>
            <div className="last-login">
              <p className="last-login-p">
                <CheckBox value={isActive} readOnly /> Active
              </p>
            </div>
          </div>
        </div>
        {/* <div style={{ display: 'flex', gap: '15px' }}>
          <Button onClick={() => setOpenModal(true)}>Olá</Button>
        </div> */}
      </header>

      <Content>
        <div style={{ alignSelf: 'start', marginBottom: '24px' }}>
          <div className="int-box">
            Last 10 Log-ins
            <hr className="red-separator" />
          </div>
          <BoardBody>
            <DataGrid
              showBorders={false}
              showColumnHeaders
              hoverStateEnabled
              dataSource={systemLogins}
              onCellClick={e => {
                if (e.rowType === 'data') {
                  window.open(
                    `/professionals/${e.data.idProfessional}`,
                    `Window${e.data.idProfessional}`,
                  );
                }
              }}
            >
              <SearchPanel visible placeholder="Search by name or role" />
              <HeaderFilter visible allowSearch />
              <Paging pageSize={10} />
              <Column
                dataField="professional.name"
                caption="Professional"
                cellRender={professionalCell}
              />
              <Column
                dataField="signInAt"
                allowHeaderFiltering={false}
                width={140}
                cellRender={signInAtCell}
                alignment="center"
              />
              <Column
                dataField="signInRole"
                allowHeaderFiltering={false}
                cellRender={greyTextCells}
                width={140}
                alignment="center"
              />
            </DataGrid>
          </BoardBody>
        </div>

        <div style={{ alignSelf: 'start', marginBottom: '24px' }}>
          <div className="int-box">
            <p>Professionals</p>
            <small>Professionals that have access to this system</small>
            <hr className="red-separator" />
          </div>
          <BoardBody>
            <DataGrid
              showBorders={false}
              showColumnHeaders
              hoverStateEnabled
              keyExpr="id"
              dataSource={systemProfessionals}
              onCellClick={e => {
                if (e.rowType === 'data') {
                  window.open(
                    `/professionals/${e.data.idProfessional}`,
                    `Window${e.data.idProfessional}`,
                  );
                }
              }}
            >
              <Grouping contextMenuEnabled />
              <GroupPanel visible />
              <HeaderFilter visible allowSearch />
              <Paging pageSize={15} />
              <Column
                allowGrouping={false}
                dataField="professional.name"
                caption="Professional"
                cellRender={professionalCell}
              />
              <Column dataField="groupName" cellRender={boldCell} />
            </DataGrid>
          </BoardBody>
        </div>
      </Content>
    </Container>
  );
};
